<template>
  <div class="admin-shop-order-edit">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Shopify Order Edit</h2></b-card-header>
        <b-card-body>
          <admin-shop-order-edit :order-id="orderId"></admin-shop-order-edit>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import AdminShopOrderEdit from '../../components/admin/AdminEditShopOrder'

export default {
  name: 'admin-shop-orders',
  components: {
    AdminShopOrderEdit
  },
  data: function () {
    return {
      orderId: null
    }
  },
  created () {
    if (this.$route.params.hasOwnProperty('order_id')) {
      console.log('Viuew ROute: ', this.$route.params.order_id)
      this.orderId = this.$route.params.order_id
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-shop-order-edit {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
